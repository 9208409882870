<template>
	<div>
		<pui-datatable
			v-if="isSuperAdmin"
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		>
		</pui-datatable>
		<pui-datatable
			v-else
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'aqversionsfocfactorgrid',
	data() {
		return {
			model: 'aqversionsfocfactor',
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			isSuperAdmin: false
		};
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	}
};
</script>

<style lang="postcss" scoped></style>
